import React from 'react'
import PropTypes from 'prop-types'
import { decode } from '../../../utils/textToHtml'
import Rating from '../Rating'

const UserReviews = ({ reviews = [], withTitle = true, limit = 10 }) => {
  const totalReviews = reviews.length
  const limitReviews = reviews.slice(0, limit)

  if (totalReviews === 0) return null
  return (
    <div className="pt-2">
      {withTitle && (
        <p className="font-bold">
          {totalReviews} {`${totalReviews <= 1 ? 'Review' : 'Reviews'}`}
        </p>
      )}
      <div>
        {limitReviews.map(({ node }) => {
          return (
            <div className="w-full border-t py-4">
              <div className="mb-3 flex">
                <img
                  alt="user profile"
                  src={`https://res.cloudinary.com/dvjhq5dpg/image/fetch/h_80,w_80,c_thumb,r_max/v1546964241/http://dev-skillsailors.pantheonsite.io/${node.relationships.uid.relationships.user_picture.uri.url}`}
                  className="w-12"
                />
                <div className="ml-2">
                  <h3>{node.relationships.uid.name}</h3>
                  <p className="m-0 text-sm text-gray-700">
                    Member since {node.relationships.uid.created}
                  </p>
                </div>
              </div>
              <p
                className="text-base m-0 py-1"
                dangerouslySetInnerHTML={{
                  __html: decode(node.body.value),
                }}
              />
              <div className="flex items-center content-center py-2">
                <p className="w-1/2 font-bold text-base m-0 mt-1">
                  {node.created}
                </p>

                <Rating
                  enabled={false}
                  stars_rating_votes={totalReviews}
                  stars_rating_rating={node.field_user_rating}
                  display_label={false}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
UserReviews.propTypes = {
  reviews: PropTypes.object,
  withTitle: PropTypes.bool,
}
export default UserReviews
