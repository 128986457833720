import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Rating = ({
  stars_rating_votes,
  stars_rating_max = 5,
  stars_rating_rating,
  display_label = true,
  inline = true,
  enabled = true,
  handleChange = () => {},
}) => {
  const [hoverElement, setHoverElement] = useState(stars_rating_rating)

  const totalStars = stars_rating_max =>
    Array.from({ length: stars_rating_max }).fill('')

  return (
    <div
      className={`stars flex ${
        inline ? 'flex-row items-center content-center' : 'flex-col'
      }  w-full`}
      data-stars={stars_rating_rating}
    >
      <div className="flex h-5">
        {totalStars(stars_rating_max).map((_, index) => {
          const key = index + 1
          return (
            <svg
              key={key}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15 14"
              className={`mr-1 w-6 ${enabled ? 'lg:w-6' : 'lg:w-3'}`}
            >
              <path
                onMouseEnter={() => {
                  if (enabled) {
                    setHoverElement(key)
                  }
                }}
                onMouseLeave={() => {
                  if (enabled) {
                    setHoverElement(stars_rating_rating)
                  }
                }}
                onClick={() => {
                  handleChange(key)
                }}
                fill={hoverElement >= key ? '#69024c' : '#bebbbb'}
                fillRule="nonzero"
                className="
                  cursor-pointer"
                d="M8.224.44l1.692 3.345a.806.806 0 0 0 .608.431l3.783.537c.663.094.928.888.448 1.344l-2.737 2.604a.777.777 0 0 0-.232.698l.646 3.677c.113.644-.58 1.136-1.172.832l-3.384-1.736a.826.826 0 0 0-.752 0L3.74 13.908c-.592.304-1.285-.188-1.172-.832L3.214 9.4a.777.777 0 0 0-.232-.698L.245 6.097c-.48-.456-.215-1.25.448-1.344l3.783-.537a.806.806 0 0 0 .608-.43L6.776.438c.296-.585 1.152-.585 1.448 0z"
              />
            </svg>
          )
        })}
      </div>
      <div className="flex pt-1">
        {display_label && (
          <div className="text-sm text-gray-3">
            <span>{stars_rating_rating}</span>/<span>{stars_rating_max}</span>
            <span>
              (<span>{stars_rating_votes}</span>
              Votes)
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

Rating.propTypes = {
  stars_rating_votes: PropTypes.number.isRequired,
  stars_rating_max: PropTypes.number.isRequired,
  stars_rating_rating: PropTypes.number.isRequired,
  display_label: PropTypes.bool,
  inline: PropTypes.bool,
  minimal_label: PropTypes.bool,
  isGlobal: PropTypes.bool,
  recipeID: PropTypes.number,
}

export default Rating
