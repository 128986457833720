import React, { useState } from 'react'
import Rating from '../Rating'
import { Entity } from 'drupal-jsonapi-client'

const UserReviewForm = ({ user, userId }) => {
  const [rating, setRating] = useState(0)
  const [body, setBody] = useState('')
  const [submit, setSubmit] = useState(false)
  const [userReview] = useState(new Entity('node', 'user_review'))

  if (!user) return null

  const onSubmit = async evt => {
    evt.preventDefault()
    try {
      setSubmit(true)
      userReview.setAttribute('title', body.substr(0, 25))
      userReview.setAttribute('body', {
        value: body,
        format: 'basic_html',
      })
      userReview.setAttribute('field_user_rating', rating)
      userReview.setRelationship('field_review_user', {
        data: {
          type: 'user--user',
          id: userId,
        },
      })

      await userReview.save()
      setSubmit(false)
      setBody('')
      setRating(0)
    } catch (ex) {
      setSubmit(false)
    }
  }

  return (
    <form onSubmit={onSubmit} className="w-full">
      <label className="font-bold my-2" htmlFor="reviewText">
        Add Your Review
      </label>

      <textarea
        className="border w-full p-1 text-base"
        name="reviewText"
        id="reviewText"
        cols="30"
        rows="5"
        value={body}
        onChange={evt => {
          setBody(evt.target.value)
        }}
      ></textarea>
      <div className="py-2">
        <Rating
          handleChange={setRating}
          stars_rating_rating={rating}
          display_label={false}
        />
      </div>
      <div className="py-2">
        <button className="w-full bg-purple-1 hover:bg-blue-1 font-semibold text-white py-2 px-4 border border-purple-1 hover:border-transparent rounded md:w-auto">
          {submit ? 'Sending Review...' : 'Send Review'}
        </button>
      </div>
    </form>
  )
}

export default UserReviewForm
